/* eslint-disable indent */
/* eslint-disable class-methods-use-this */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable global-require */
/* eslint-disable no-useless-concat */
/* eslint-disable no-useless-escape */
// import axios from 'axios'
import instance from '@/libs/axios.instance'
import authHeader from '@/libs/auth.header'

class PaymentTypeService {

	getPaymentTypeLists(queryParams) {
		const pars = {
			name: queryParams.name,
			code: queryParams.code,
			status: queryParams.status,
            type: queryParams.type,
			pageNumber: queryParams.page,
		}
		return instance.get('/api/payment-type/index', { headers: authHeader(), params: pars })
	}

    addPaymentType(paymentTypeData) {
		const FormData = require('form-data')
		const data = new FormData()
		data.append('name', paymentTypeData.name)
		data.append('code', paymentTypeData.code)
		data.append('transaction_type', paymentTypeData.transaction_type)
		data.append('merchant_fee', paymentTypeData.merchant_fee)
		data.append('owner_fee', paymentTypeData.owner_fee)
		data.append('status', paymentTypeData.status)
		data.append('min_amount', paymentTypeData.min_amount * 1000)
		data.append('max_amount', paymentTypeData.max_amount * 1000)

		return instance.post('/api/payment-type/create', data, { headers: authHeader() })
    }

    editPaymentType(paymentTypeData) {
		const FormData = require('form-data')
		const data = new FormData()
		data.append('paymentTypeId', paymentTypeData.paymentTypeId)
        // data.append('name', paymentTypeData.name)
		data.append('code', paymentTypeData.code)
		data.append('transaction_type', paymentTypeData.transaction_type)
		data.append('merchant_fee', paymentTypeData.merchant_fee)
		// data.append('owner_fee', paymentTypeData.owner_fee)
		data.append('status', paymentTypeData.status)
		data.append('min_amount', paymentTypeData.min_amount * 1000)
		data.append('max_amount', paymentTypeData.max_amount * 1000)

		return instance.post('/api/payment-type/edit', data, { headers: authHeader() })
    }
}

export default new PaymentTypeService()
