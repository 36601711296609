<template>
	<b-card no-body>
		<b-card-body>
			<b-row class="filter">
				<!-- Name -->
				<b-col
					cols="12"
					md="3"
					class="mb-2"
				>
					<label>{{ $t('Code') }}</label>
					<b-form-input
						v-model="filter.code"
						:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						class="w-100"
					/>
				</b-col>
				<!-- Name -->
				<b-col
					cols="12"
					md="3"
					class="mb-2"
				>
					<label>{{ $t('Name') }}</label>
					<b-form-input
						v-model="filter.name"
						:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						class="w-100"
					/>
				</b-col>
				<!-- Type -->
				<b-col
					cols="12"
					md="3"
					class="mb-md-0 mb-2"
				>
					<label>{{ $t('Transaction type') }}</label>
					<v-select
						:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						v-model="filter.transactionType"
						:options="transactionTypeOptions"
						class="w-100"
						:reduce="val => val.value"
					/>
				</b-col>
				<!-- Status -->
				<b-col
					cols="12"
					md="3"
					class="mb-md-0 mb-2"
				>
					<label>{{ $t('Status') }}</label>
					<v-select
						:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						v-model="filter.status"
						:options="statusOptions"
						class="w-100"
						:reduce="val => val.value"
					/>
				</b-col>
				<!-- action -->
				<b-col
					cols="12"
					class="d-flex justify-content-end mt-10"
				>
					<b-button
						variant="outline-secondary"
						@click="resetFilter()"
					>
						{{ $t('Reset') }}
					</b-button>
					<b-button
						variant="primary"
						class="ml-2"
						@click="searchFilter()"
					>
						{{ $t('Search') }}
					</b-button>
				</b-col>
			</b-row>
		</b-card-body>
	</b-card>
</template>

<script>
import {
	BFormInput, BCard, BCardBody, BRow, BCol, BButton,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import vSelect from 'vue-select'

export default {
	components: {
		BButton,
		BRow,
		BCol,
		BCard,
		BFormInput,
		BCardBody,
		vSelect,
	},
	props: {
		codeFilter: {
			type: [String, null],
			default: null,
		},
		nameFilter: {
			type: [String, null],
			default: null,
		},
		transactionTypeFilter: {
			type: [Number, null],
			default: null,
		},
		statusFilter: {
			type: [Number, null],
			default: null,
		},
		statusOptions: {
			type: Array,
			required: true,
		},
		transactionTypeOptions: {
			type: Array,
			required: true,
		},
	},
	methods: {
	},
	setup(props, { emit }) {
		const filter = ref({
			code: props.codeFilter,
			name: props.nameFilter,
			transactionType: props.transactionTypeFilter,
			status: props.statusFilter,
		})
		const resetFilter = () => {
			emit('update:codeFilter', null)
			emit('update:nameFilter', null)
			emit('update:transactionTypeFilter', null)
			emit('update:statusFilter', null)
			filter.value = {
				code: null,
				name: null,
				transactionType: null,
				status: null,
			}
			emit("refetch-data");
		}

		const searchFilter = () => {
			emit('update:codeFilter', filter.value.code)
			emit('update:nameFilter', filter.value.name)
			emit('update:transactionTypeFilter', filter.value.transactionType)
			emit('update:statusFilter', filter.value.status)
			emit("refetch-data");
		}
		return {
			resetFilter,
			searchFilter,
			filter,
		}
	}
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
