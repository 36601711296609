<template>
  <div>
    <payment-type-list-filters
      v-if="transactionTypeOptions"
      :code-filter.sync="codeFilter"
      :name-filter.sync="nameFilter"
      :transaction-type-filter.sync="transactionTypeFilter"
      :transaction-type-options="transactionTypeOptions"
      :status-filter.sync="statusFilter"
      :status-options="statusOptions"
      @refetch-data="refetchData"
    />
    <b-card no-body>
      <b-table
        ref="refPaymentTypeListTable"
        class="position-relative table-white-space mb-0"
        :items="fetchPaymentTypes"
        responsive
        :fields="tableColumns"
        primary-key="index"
        show-empty
      >
        <!-- <template #empty="scope">
					<div class="text-center p-1" style="opacity: 0.3">
						<feather-icon
							icon="ArchiveIcon"
							size="36"
						/></br>
						<span class="">No Data</span>
					</div>
				</template> -->
        <template #cell(index)="data">
          {{ perPage * (currentPage - 1) + data.index + 1 }}
        </template>

        <template #cell(transaction_type)="data">
          {{ resolveTransactionType(data.item.transaction_type) }}
        </template>
        <template #cell(status)="data">
          <div :class="`text-capitalize align-items-center d-flex`">
            <span
              :class="`dot bg-${resolveStatus(data.item.status).variant}`"
            />{{ resolveStatus(data.item.status).label }}
          </div>
        </template>

        <template #cell(name)="data">
          <b-link
            class="font-weight-bold cursor-pointer text-primary"
            @click="
              showEditPaymentTypeModal({
                paymentTypeId: data.item.id,
                name: data.item.name,
                code: data.item.code,
                currency: data.item.currency,
                transaction_type: data.item.transaction_type,
                merchant_fee: data.item.merchant_fee,
                owner_fee: data.item.owner_fee,
                status: data.item.status,
                min_amount: data.item.min_amount,
                max_amount: data.item.max_amount,
              })
            "
          >
            {{ data.item.name }}
          </b-link>
        </template>

        <template #cell(min_amount)="data">
          {{ numberFormat(parseFloat(data.item.min_amount) / 1000) }}
        </template>

        <template #cell(max_amount)="data">
          {{ numberFormat(parseFloat(data.item.max_amount) / 1000) }}
        </template>

        <template #cell(createdAt)="data">
          {{
            formatDateTime(
              data.item.created_at ? data.item.created_at : ""
            ).replace(".000000Z", "")
          }}
        </template>

        <template #cell(updatedAt)="data">
          {{
            formatDateTime(
              data.item.updated_at ? data.item.updated_at : ""
            ).replace(".000000Z", "")
          }}
        </template>

        <template #cell(action)="data">
          <b-link
            v-b-tooltip.hover.v-info
            :title="$t('Edit')"
            v-if="$can('edit', 'paymenttype')"
            class="font-weight-bold text-nowrap"
            @click="
              showEditPaymentTypeModal({
                paymentTypeId: data.item.id,
                name: data.item.name,
                code: data.item.code,
                currency: data.item.currency,
                transaction_type: data.item.transaction_type,
                merchant_fee: data.item.merchant_fee,
                owner_fee: data.item.owner_fee,
                status: data.item.status,
                min_amount: data.item.min_amount,
                max_amount: data.item.max_amount,
              })
            "
          >
            <feather-icon icon="Edit3Icon" size="16" />
          </b-link>
        </template>
      </b-table>
      <div class="p-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalPaymentTypes"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <!-- Add Payment Type Modal 
		<add-payment-type-modal
			:transaction-type-options="transactionTypeOptions"
			@refetch-data="refetchData"
		/>-->

    <!-- Edit Payment Type Modal -->
    <edit-payment-type-modal
      :transaction-type-options="transactionTypeOptions"
      :payment-type-detail.sync="paymentTypeDetail"
      @refetch-data="refetchData"
    />
  </div>
</template>

<script>
import {
  BButton,
  BModal,
  VBModal,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BTr,
  BTh,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormGroup,
  BTooltip,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ref, watch, computed, onUnmounted } from "@vue/composition-api";
import { numberFormat, formatDateTime } from "@core/utils/filter";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import PaymentTypeListFilters from "./PaymentTypeListFilters.vue";
import paymentTypeStoreModule from "./paymentTypeStoreModule";
import i18n from "@/libs/i18n";

export default {
  components: {
    PaymentTypeListFilters,
    // AddPaymentTypeModal: () => import('./AddPaymentTypeModal.vue'),
    editPaymentTypeModal: () => import("./EditPaymentTypeModal.vue"),
    BButton,
    BFormGroup,
    BModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BTr,
    BTh,
    BMedia,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      paymentTypeDetail: null,
      content: null,
    };
  },
  methods: {
    showAddPaymentTypeModal() {
      this.$bvModal.show("modal-add-payment-type");
    },
    showEditPaymentTypeModal(datas) {
      this.paymentTypeDetail = datas;
      this.$bvModal.show("modal-edit-payment-type");
    },
  },
  setup() {
    // Register module
    if (!store.hasModule("payment-type"))
      store.registerModule("payment-type", paymentTypeStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule("payment-type"))
        store.unregisterModule("payment-type");
    });
    const toast = useToast();
    const PaymentTypeLists = ref([]);
    const perPage = ref(25);
    const totalPaymentTypes = ref(0);
    const currentPage = ref(1);
    const codeFilter = ref(null);
    const nameFilter = ref(null);
    const transactionTypeFilter = ref(null);
    const statusFilter = ref(null);
    const refPaymentTypeListTable = ref(null);
    const tableColumns = [
      { key: "index", label: "#" },
      { key: "name", label: i18n.t("Name") },
      { key: "code", label: i18n.t("Code") },
      { key: "transaction_type", label: i18n.t("Transaction type") },
      // { key: 'currency', label: i18n.t('Currency') },
      { key: "merchant_fee", label: "Affiliate Fee" },
      { key: "status", label: i18n.t("Status") },
      { key: "min_amount", label: i18n.t("Min amount") },
      { key: "max_amount", label: i18n.t("Max amount") },
      // { key: 'created_by', label: i18n.t('Created by') },
      { key: "updated_by", label: i18n.t("Updated by") },
      { key: "createdAt", label: i18n.t("Created at") },
      { key: "updatedAt", label: i18n.t("Updated at") },
      // { key: 'action', stickyColumn: true, label: 'Action', variant: 'danger' },
      { key: "action", label: i18n.t("Actions") },
    ];

    const refetchData = () => {
      refPaymentTypeListTable.value.refresh();
    };

    watch(
      [
        currentPage,
      ],
      () => {
        refetchData();
      }
    );

    const fetchPaymentTypes = (ctx, callback) => {
      store
        .dispatch("payment-type/fetchPaymentTypes", {
          page: currentPage.value,
          name: nameFilter.value,
          code: codeFilter.value,
          status: statusFilter.value,
          type: transactionTypeFilter.value,
        })
        .then((response) => {
          if (response.data.code === "00") {
            callback(response.data.data);
            totalPaymentTypes.value = response.data.count;
            perPage.value =
              currentPage.value === 1 ? response.data.data.length : 25;
          } else {
            toast({
              component: ToastificationContent,
              props: {
                title: response.data.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        })
        .catch((error) => {
          const { response } = error;
          toast({
            component: ToastificationContent,
            props: {
              title: response.data.message || "Error fetching cash logs list",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    };

    const dataMeta = computed(() => {
      const localItemsCount = refPaymentTypeListTable.value
        ? refPaymentTypeListTable.value.localItems.length
        : 0;
      return {
        from:
          perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalPaymentTypes.value,
      };
    });

    const resolveTransactionType = (type) => {
      if (type === 1) return i18n.t("Deposit");
      if (type === 2) return i18n.t("Withdrawal");
    };

    const transactionTypeOptions = [
      { label: i18n.t("Deposit"), value: 1 },
      { label: i18n.t("Withdrawal"), value: 2 },
    ];

    const statusOptions = [
      { label: i18n.t("Active"), value: 1 },
      { label: i18n.t("Inactive"), value: 2 },
    ];

    const resolveStatus = (status) => {
      if (status === 1) return { label: i18n.t("Active"), variant: "primary" };
      if (status === 2) return { label: i18n.t("Inactive"), variant: "danger" };
      return { label: i18n.t("Pending"), variant: "secondary" };
    };
    return {
      fetchPaymentTypes,
      tableColumns,
      perPage,
      currentPage,
      totalPaymentTypes,
      dataMeta,
      refPaymentTypeListTable,

      PaymentTypeLists,
      refetchData,

      codeFilter,
      nameFilter,
      transactionTypeFilter,
      transactionTypeOptions,
      statusFilter,

      resolveStatus,
      statusOptions,

      formatDateTime,
      numberFormat,
      resolveTransactionType,
    };
  },
};
</script>
<style>
.b-table-sticky-column {
  right: 0 !important;
}
td.b-table-sticky-column {
  background-color: inherit;
}
</style>