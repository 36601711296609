import PaymentTypeService from '@/libs/payment-type.service'

export default {
	namespaced: true,
	state: {},
	getters: {},
	mutations: {},
	actions: {
		fetchPaymentTypes(ctx, queryParams) {
			return new Promise((resolve, reject) => {
				PaymentTypeService.getPaymentTypeLists(queryParams)
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},
		addPaymentType(ctx, paymentTypeData) {
			return new Promise((resolve, reject) => {
				PaymentTypeService.addPaymentType(paymentTypeData)
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},
		editPaymentType(ctx, paymentTypeData) {
			return new Promise((resolve, reject) => {
				PaymentTypeService.editPaymentType(paymentTypeData)
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		}
	}
}
